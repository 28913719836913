import React, { useCallback, useState } from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Input,
} from 'reactstrap';
import { fetchAll } from 'services/attendanceService';
import SpinnerLoader from 'components/Misc/Spinner';
import DatePickerComp from 'components/DateRangePicker/DatePicker';
import _debounce from 'lodash/debounce';
import moment from 'moment';
import { getDateFormat } from 'services/utilService';
import { getTimeFormat } from 'services/utilService';
import { checkoutTime } from 'services/utilService';
import { getDuration } from 'services/utilService';
import { checkin } from 'services/attendanceService';
import { checkout } from 'services/attendanceService';
import toast from 'react-hot-toast';
import UpdateModal from 'components/Modals/UpdateTime';
import { update } from 'services/attendanceService';
import Papa from 'papaparse';
import { downloadData } from 'services/utilService';
import * as _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { MultiDropdown } from 'components/Misc/MultiDropdown';
import { roleFilter } from 'shared/constants';
import { ROLES } from 'shared/constants';
import { handleError } from 'services/utilService';
import { successToast } from 'shared/constants';

function Attendance() {
  const history = useHistory();

  const pageSize = 10;
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [showSpinner, setSpinner] = useState(true);
  const [date, setDate] = useState(new Date());
  const [showModal, setModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [role, setRole] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [initialCheckin, setInitialCheckin] = useState();
  const [initialCheckout, setInitialCheckout] = useState();
  const [shownRow, setShownRow] = useState();

  React.useEffect(() => {
    fetchUsers({ date });
  }, [date]);

  const fetchUsers = async (body = null) => {
    setSpinner(true);
    fetchAll(body)
      .then((data) => {
        setUsers(data.data);
        setCount(data.meta.total_count);
        setSpinner(false);
      })
      .catch((error) => {
        setSpinner(false);
        handleError(error);
      });
  };

  const pages = () => {
    let pagesArr = [];
    for (let i = 1; i <= Math.ceil(count / pageSize); i++) {
      pagesArr.push(
        <PaginationItem className={currentPage === i ? 'active' : ''}>
          <PaginationLink onClick={(e) => handlePageClick(e, i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return pagesArr;
  };

  const handlePageClick = (e, pageNumber) => {
    e.preventDefault();
    fetchUsers({
      size: pageSize,
      pageNo: pageNumber,
      ...(search.trim() && { search: search.trim() }),
      date,
    });
    setPage(pageNumber);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    debounceFn(e.target.value);
  };

  function debounceSearch(val, roleVal = role) {
    fetchUsers({
      size: pageSize,
      pageNo: 1,
      ...(val.trim() && { search: val.trim() }),
      date,
      ...(roleVal && roleVal.length && { role: roleVal }),
    });
  }

  const debounceFn = useCallback(_debounce(debounceSearch, 500), []);

  const changeRole = (value) => {
    setRole(value);
    debounceFn(search, value);
  };

  const handleCheckin = async (e, user, role) => {
    try {
      setSpinner(true);
      await checkin({ userId: user._id, date: date.toISOString(), role });
      fetchUsers({
        size: pageSize,
        pageNo: currentPage,
        ...(search.trim() && { search: search.trim() }),
        date,
      });
      toast.success('User Check In Updated!', successToast);
    } catch (error) {
      setSpinner(false);
      handleError(error);
    }
  };

  const handleCheckout = async (e, user, role) => {
    try {
      setSpinner(true);
      await checkout({ userId: user._id, date: date.toISOString(), role });
      fetchUsers({
        size: pageSize,
        pageNo: currentPage,
        ...(search.trim() && { search: search.trim() }),
        date,
      });
      toast.success('User Check Out Updated!', successToast);
    } catch (error) {
      setSpinner(false);
      handleError(error);
    }
  };

  const updateTimeHandler = (e, user, role) => {
    setSelectedUser(user._id);
    setInitialCheckin(
      getAttendanceByRole(user.attendance, role)?.checkIn
        ? new Date(getAttendanceByRole(user.attendance, role)?.checkIn)
        : ''
    );
    setInitialCheckout(
      getAttendanceByRole(user.attendance, role)?.checkOut
        ? new Date(getAttendanceByRole(user.attendance, role)?.checkOut)
        : ''
    );
    setSelectedRole(role);
    setModal(true);
  };

  const handleUpdate = async (times) => {
    try {
      setSpinner(true);
      setModal(false);
      const checkIn = moment(date).toDate();
      const selectedCheckin = moment(times.checkin).toDate();
      checkIn.setHours(selectedCheckin.getHours());
      checkIn.setMinutes(selectedCheckin.getMinutes());
      checkIn.setSeconds(0);
      checkIn.setMilliseconds(0);

      const checkOut = moment(date).toDate();
      const selectedCheckout = moment(times.checkout).toDate();
      checkOut.setHours(selectedCheckout.getHours());
      checkOut.setMinutes(selectedCheckout.getMinutes());
      checkOut.setSeconds(0);
      checkOut.setMilliseconds(0);

      await update(
        { userId: selectedUser, date: date.toISOString(), role: selectedRole },
        { checkIn, checkOut }
      );
      fetchUsers({
        size: pageSize,
        pageNo: currentPage,
        ...(search.trim() && { search: search.trim() }),
        date,
      });
      toast.success('Time updated!', successToast);
    } catch (error) {
      setSpinner(false);
      handleError(error);
    }
  };

  const exportData = async () => {
    setSpinner(true);
    let data = await fetchAll({
      fetchAll: true,
    });
    data = data.data.map((user) => {
      return {
        NAME: (user?.firstName || '') + ' ' + (user?.lastName || ''),
        EMAIL: user?.email,
        DATE: getDateFormat(date),
        'CHECK IN': getTimeFormat(user.attendance[0]?.checkIn),
        'CHECK OUT': checkoutTime(
          user.attendance[0]?.checkIn,
          user.attendance[0]?.checkOut,
          date
        ),
        'USER TYPE': _.capitalize(user?.role),
        DURATION: getDuration(
          user.attendance[0]?.checkIn,
          user.attendance[0]?.checkOut,
          date
        ),
      };
    });
    const csvData = Papa.unparse(data);
    downloadData(
      csvData,
      `${moment(date).format('DD MMM, yyyy')}.csv`,
      'text/csv;charset=utf-8;'
    );
    setSpinner(false);
  };

  const getActionDropdown = (user, role) => (
    <UncontrolledDropdown>
      <DropdownToggle
        className="btn-icon-only text-light action-bg"
        color=""
        role="button"
        size="sm"
      >
        <img src="/action.svg" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-arrow" right>
        {!getAttendanceByRole(user.attendance, role)?.checkIn &&
          !getAttendanceByRole(user.attendance, role)?.checkOut &&
          moment(date).isSame(moment(), 'day') && (
            <>
              <DropdownItem onClick={(e) => handleCheckin(e, user, role)}>
                <div className="d-flex align-items-center justify-content-start dropdown-menu-item">
                  <div>Check In</div>
                </div>
              </DropdownItem>
              <DropdownItem divider />
            </>
          )}
        {getAttendanceByRole(user.attendance, role)?.checkIn &&
          !getAttendanceByRole(user.attendance, role)?.checkOut &&
          moment(date).isSame(moment(), 'day') && (
            <>
              <DropdownItem onClick={(e) => handleCheckout(e, user, role)}>
                <div className="d-flex align-items-center justify-content-start dropdown-menu-item">
                  <div>Check Out</div>
                </div>
              </DropdownItem>
              <DropdownItem divider />
            </>
          )}
        <DropdownItem onClick={(e) => updateTimeHandler(e, user, role)}>
          <div className="d-flex align-items-center justify-content-start dropdown-menu-item">
            <div>Update Time</div>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const getAttendanceByRole = (attendance, role) => {
    return attendance.find((at) => at.role === role);
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <Container className="mt-3" fluid>
        <div className="mb-3 d-flex align-items-center justify-content-end">
          <div className="search-input mr-3">
            <div className="search-icon">
              <img src="/search-icon.svg" />
            </div>
            <Input
              className="searchBox"
              placeholder="Search"
              type="text"
              value={search}
              onChange={handleSearch}
            />
          </div>
          <div className="date-picker">
            <DatePickerComp
              date={date}
              setDate={setDate}
              maxDate={moment().toDate()}
            />
          </div>
        </div>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <div className="table-header">
                  <div className="header-left">
                    <div className="table-title">Check In/Out</div>
                  </div>
                  <div className="header-right">
                    <div className="export-container mr-4">
                      <div
                        className="export"
                        onClick={exportData}
                        role="button"
                      >
                        Export
                      </div>
                    </div>
                    <div>
                      <MultiDropdown
                        placeholder={'User Type'}
                        value={role}
                        options={roleFilter}
                        valueChange={changeRole}
                      />
                    </div>
                  </div>
                </div>
              </CardHeader>
              <div className="table-responsive">
                <Table className="dataTable align-items-center">
                  <thead className="thead-bh icon-color-light">
                    <tr>
                      <th className="w-20 name-field" scope="col">
                        NAME
                      </th>
                      <th className="w-15" scope="col">
                        DATE
                      </th>
                      <th className="w-15" scope="col">
                        CHECK IN
                      </th>
                      <th className="w-15" scope="col">
                        CHECK OUT
                      </th>
                      <th className="w-15" scope="col">
                        USER TYPE
                      </th>
                      <th className="w-15" scope="col">
                        DURATION
                      </th>
                      <th className="w-5" scope="col"></th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {users.length ? (
                      users.map((user, index) => (
                        <>
                          <tr key={index}>
                            <td className="overflowStyle text-capitalize pl-0">
                              <div className="user-info-cell">
                                <div className="user-image">
                                  <img src="user-image.png" />
                                </div>
                                <div
                                  className="username overflowStyle"
                                  role="button"
                                  onClick={() =>
                                    history.push(`/admin/user/${user._id}`)
                                  }
                                >
                                  {(user?.firstName || '') +
                                    ' ' +
                                    (user?.lastName || '')}
                                </div>
                              </div>
                            </td>
                            <td className="overflowStyle text-capitalize pl-0 pr-0">
                              {getDateFormat(date)}
                            </td>
                            <td className="overflowStyle text-capitalize pl-0 pr-0">
                              {!user?.hybridRole &&
                                getTimeFormat(user.attendance[0]?.checkIn)}
                            </td>
                            <td className="overflowStyle pl-0 pr-0">
                              {!user?.hybridRole &&
                                checkoutTime(
                                  user.attendance[0]?.checkIn,
                                  user.attendance[0]?.checkOut,
                                  date
                                )}
                            </td>
                            <td className="overflowStyle pl-0 pr-0">
                              {!user?.hybridRole ? (
                                _.capitalize(user.role)
                              ) : (
                                <img
                                  src="/hybrid-role.svg"
                                  className="small-icon"
                                />
                              )}
                            </td>
                            <td className="overflowStyle pl-0 pr-0">
                              {!user?.hybridRole &&
                                getDuration(
                                  user.attendance[0]?.checkIn,
                                  user.attendance[0]?.checkOut,
                                  date
                                )}
                            </td>
                            <td className="actionDropdown px-0">
                              {!user?.hybridRole ? (
                                getActionDropdown(user, user.role)
                              ) : (
                                <img
                                  className="xsmall-icon"
                                  src="/chevron-down.svg"
                                  alt="chevron"
                                  role="button"
                                  onClick={() =>
                                    setShownRow((prev) =>
                                      prev > -1 && prev === index ? -1 : index
                                    )
                                  }
                                />
                              )}
                            </td>
                          </tr>
                          {user?.hybridRole && shownRow === index && (
                            <>
                              <tr>
                                <td></td>
                                <td></td>
                                <td>
                                  {getTimeFormat(
                                    getAttendanceByRole(
                                      user.attendance,
                                      ROLES.trainer
                                    )?.checkIn
                                  )}
                                </td>
                                <td>
                                  {checkoutTime(
                                    getAttendanceByRole(
                                      user.attendance,
                                      ROLES.trainer
                                    )?.checkIn,
                                    getAttendanceByRole(
                                      user.attendance,
                                      ROLES.trainer
                                    )?.checkOut,
                                    date
                                  )}
                                </td>
                                <td>{_.capitalize(ROLES.trainer)}</td>
                                <td>
                                  {getDuration(
                                    getAttendanceByRole(
                                      user.attendance,
                                      ROLES.trainer
                                    )?.checkIn,
                                    getAttendanceByRole(
                                      user.attendance,
                                      ROLES.trainer
                                    )?.checkOut,
                                    date
                                  )}
                                </td>
                                <td className="pl-1 pr-0">
                                  {getActionDropdown(user, ROLES.trainer)}
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td></td>
                                <td>
                                  {getTimeFormat(
                                    getAttendanceByRole(
                                      user.attendance,
                                      ROLES.trainee
                                    )?.checkIn
                                  )}
                                </td>
                                <td>
                                  {checkoutTime(
                                    getAttendanceByRole(
                                      user.attendance,
                                      ROLES.trainee
                                    )?.checkIn,
                                    getAttendanceByRole(
                                      user.attendance,
                                      ROLES.trainee
                                    )?.checkOut,
                                    date
                                  )}
                                </td>
                                <td>{_.capitalize(ROLES.trainee)}</td>
                                <td>
                                  {getDuration(
                                    getAttendanceByRole(
                                      user.attendance,
                                      ROLES.trainee
                                    )?.checkIn,
                                    getAttendanceByRole(
                                      user.attendance,
                                      ROLES.trainee
                                    )?.checkOut,
                                    date
                                  )}
                                </td>
                                <td className="pl-1 pr-0">
                                  {getActionDropdown(user, ROLES.trainee)}
                                </td>
                              </tr>
                            </>
                          )}
                        </>
                      ))
                    ) : (
                      <></>
                    )}
                    <tr></tr>
                  </tbody>
                </Table>
                {!showSpinner && !users.length && (
                  <div className="d-flex align-items-center justify-content-center mb-3">
                    No records found
                  </div>
                )}
              </div>
              <CardFooter className="py-4 custom-footer">
                <nav
                  className="d-flex align-items-center justify-content-end"
                  aria-label="..."
                >
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem
                      className={currentPage === 1 ? 'disabled' : ''}
                    >
                      <PaginationLink
                        onClick={(e) => handlePageClick(e, currentPage - 1)}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left icon-color-light" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    {pages().map((page, index) => (
                      <div key={index}>{page}</div>
                    ))}
                    <PaginationItem
                      className={
                        currentPage >= Math.ceil(count / pageSize)
                          ? 'disabled'
                          : ''
                      }
                    >
                      <PaginationLink
                        onClick={(e) => handlePageClick(e, currentPage + 1)}
                      >
                        <i className="fas fa-angle-right icon-color-light" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      {showModal && (
        <UpdateModal
          open={showModal}
          handleClose={() => setModal(false)}
          handleUpdate={handleUpdate}
          initialCheckin={initialCheckin}
          initialCheckout={initialCheckout}
        />
      )}
    </>
  );
}

export default Attendance;
