import { authorizedPostCall, authorizedPutCall } from './APIsService';

export const fetchAll = async (body) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall('/attendance/get_all', body)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

export const checkin = async ({ userId, date, role }) => {
  return new Promise((resolve, reject) => {
    authorizedPutCall(
      `/attendance/checkin?userId=${userId}&date=${date}&role=${role}`
    )
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

export const checkout = async ({ userId, date, role }) => {
  return new Promise((resolve, reject) => {
    authorizedPutCall(
      `/attendance/checkout?userId=${userId}&date=${date}&role=${role}`
    )
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

export const changeStatus = async ({ userId, date, role }) => {
  return new Promise((resolve, reject) => {
    authorizedPutCall(
      `/attendance/change_status?userId=${userId}&date=${date}&role=${role}`
    )
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

export const update = async ({ userId, date, role }, body) => {
  return new Promise((resolve, reject) => {
    authorizedPutCall(
      `/attendance/update?userId=${userId}&date=${date}&role=${role}`,
      body
    )
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};
