import React, { useEffect } from 'react';
import classnames from 'classnames';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from 'reactstrap';
import AuthHeader from 'components/Headers/AuthHeader.js';
import { login } from 'services/authService';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { errorToast, updateToast } from 'shared/constants';
import SpinnerLoader from 'components/Misc/Spinner';
import { ReactComponent as CheckBoxIcon } from './../../assets/checkbox.svg';
import './Auth.css';
import { isLoggedIn } from 'services/authService';

function Login() {
  const history = useHistory();

  useEffect(() => {
    isLoggedIn() && history.push('/admin/users');
  }, []);

  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showSpinner, setSpinner] = React.useState(false);
  const [rememberMe, setRememberMe] = React.useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(true);

  const setRememberMeInput = (checkboxElement) => {
    checkboxElement.style.fill = rememberMe ? 'white' : 'black';

    setRememberMe(!rememberMe);
  };

  const setPasswordVisibility = (e) => {
    const passwordInput = document.querySelector('.password-input input');
    passwordInput.type =
      passwordInput.type === 'password' ? 'text' : 'password';

    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleLogin = async () => {
    setSpinner(true);
    if (!email || !password) {
      toast.error('Provide Credentials to Continue', updateToast);
      setSpinner(false);
      return;
    }
    login(email, password)
      .then(() => {
        setSpinner(false);
        history.push('/admin/users');
      })
      .catch(() => {
        toast.error('Invalid Email or Password', errorToast);
        setSpinner(false);
      });
  };

  const keyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleLogin();
    }
  };

  return (
    <>
      <SpinnerLoader adminView={true} showSpinner={showSpinner} />
      <AuthHeader />
      <div className="loginForm">
        <Container className="mt-n18">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-default-login border-0 mx-4 mb-0 py-4 px-3">
                <CardBody className="px-lg-4 py-lg-3">
                  <div className="d-flex justify-content-center my-5">
                    <img
                      src={'/gym-app-logo.png'}
                      className="bt_logo"
                      alt="logo"
                    />
                  </div>
                  <div className="horizontal-ruler"></div>
                  <div className="text-center text-muted">
                    <p className="auth-title">Sign in</p>
                  </div>
                  <Form role="form" className="auth-form">
                    <FormGroup
                      className={classnames('mb-3', {
                        focused: focusedEmail,
                      })}
                    >
                      <div className="email-input">
                        <Input
                          placeholder="Email Address"
                          className="input-primary-style"
                          type="email"
                          onFocus={() => setfocusedEmail(true)}
                          onBlur={() => setfocusedEmail(true)}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          onKeyDown={keyDown}
                          autoComplete="on"
                        />
                        <div className="envelope-icon">
                          <i className="vertical-align-icon fas fa-envelope " />
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup
                      className={classnames('mb-1', {
                        focused: focusedPassword,
                      })}
                    >
                      <div className="password-input">
                        <Input
                          placeholder="Password"
                          type="password"
                          className="input-primary-style"
                          onFocus={() => setfocusedPassword(true)}
                          onBlur={() => setfocusedPassword(true)}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          onKeyDown={keyDown}
                          autoComplete="on"
                        />
                        <div className="lock-icon">
                          <img
                            className="vertical-align-icon"
                            src="/lock-icon.svg"
                          />
                        </div>
                        <div
                          role="button"
                          className="eye-icon"
                          onClick={(e) => setPasswordVisibility(e)}
                        >
                          {isPasswordVisible ? (
                            <i className="fa fa-eye-slash" />
                          ) : (
                            <i className="fas fa-eye" />
                          )}
                        </div>
                      </div>
                    </FormGroup>
                    <div className="remember-me">
                      <div className="">
                        <CheckBoxIcon
                          src="/checkbox.svg"
                          onClick={(e) =>
                            setRememberMeInput(e?.target?.closest('svg'))
                          }
                        />
                        <label
                          className=""
                          htmlFor=" customCheckLogin"
                          onClick={(e) =>
                            setRememberMeInput(
                              e?.target?.parentElement?.parentElement?.querySelector(
                                'svg'
                              )
                            )
                          }
                        >
                          <span className="text-muted remember-me-text">
                            Remember me
                          </span>
                        </label>
                      </div>
                      <div className="d-flex justify-content-center mt-3">
                        <p
                          onClick={(e) => history.push('/auth/forgot-password')}
                          className="forget-pass px-0 mb-0 text-muted forget-password-text"
                          role={'button'}
                        >
                          Forgot Password?
                        </p>
                      </div>
                    </div>

                    <div className="text-center">
                      <Button
                        className="default-button-background w-50 mt-4 py-1 login-button"
                        color="info"
                        type="button"
                        onClick={handleLogin}
                      >
                        <h3 className="mb-0 text-white py-1">Sign In</h3>
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Login;
