import { Button, Modal } from 'reactstrap';
import React from 'react';

const DeleteModal = ({ open, handleClose, handleDelete, message, title }) => {
  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={open}
        toggle={handleClose}
      >
        <div className="modal-body pt-5 px-0 pb-0">
          <div className="d-flex align-items-center justify-content-center">
            <img src="/remove.svg" alt="remove" />
          </div>
          <h2 className="text-center mt-4">{title}</h2>
          <div
            className="text-center"
            dangerouslySetInnerHTML={{
              __html: message,
            }}
          ></div>
        </div>
        <div className="py-4 px-0 modal-footer d-flex justify-content-center align-items-center">
          <Button
            onClick={handleClose}
            type="button"
            className="px-4 secondary-button"
          >
            No
          </Button>
          <Button
            onClick={handleDelete}
            className="px-4 primary-button"
            type="button"
          >
            Yes
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default DeleteModal;
