import Login from 'views/auth/Login.js';
import ForgotPassword from 'views/auth/ForgotPassword';
import ResetPassword from 'views/auth/ResetPassword';
import ForgotPasswordInstructions from 'views/auth/ForgotPasswordInstructions';
import AllUsers from 'views/attendance/AllUsers';
import Attendance from 'views/attendance/Attendance';
import Trainees from 'views/users/Trainees';
import Trainers from 'views/users/Trainers';
import UserProfile from 'views/profile/UserProfile';
import FrontDesk from 'views/users/FrontDesk';
import QRCodePage from 'views/attendance/QRCode';
import PaymentHistory from 'views/users/PaymentHistory';

export const routes = [
  {
    path: '/login',
    name: 'Login',
    miniName: 'L',
    component: Login,
    layout: '/auth',
  },
  {
    path: '/forgot-password/instructions',
    name: 'Forgot Password Instructions',
    miniName: 'FPI',
    component: ForgotPasswordInstructions,
    layout: '/auth',
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    miniName: 'FP',
    component: ForgotPassword,
    layout: '/auth',
  },
  {
    path: '/forgot-password/instructions',
    name: 'Forgot Password Instructions',
    miniName: 'FPI',
    component: ForgotPasswordInstructions,
    layout: '/auth',
  },
  {
    path: '/reset-password/:id',
    name: 'Reset Password',
    miniName: 'RP',
    component: ResetPassword,
    layout: '/auth',
  },
  {
    path: '/qr-code',
    name: 'QR Code',
    miniName: 'QR',
    component: QRCodePage,
    layout: '/auth',
  },
];

export const sidebarRoutes = [
  {
    collapse: true,
    name: 'Attendance',
    icon: '/attendance',
    state: 'dashboardsCollapse',
    views: [
      {
        path: '/users',
        name: 'All Users',
        miniName: 'D',
        component: AllUsers,
        layout: '/admin',
      },
      {
        path: '/attendance',
        name: 'Check in/out',
        miniName: 'A',
        component: Attendance,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'Users',
    icon: '/user',
    state: 'usersCollapse',
    views: [
      {
        path: '/trainees',
        name: 'Trainees',
        miniName: 'Z',
        component: Trainees,
        layout: '/admin',
      },
      {
        path: '/trainers',
        name: 'Trainers',
        miniName: 'S',
        component: Trainers,
        layout: '/admin',
      },
      {
        path: '/front-desk',
        name: 'Front-Desk',
        miniName: 'FD',
        component: FrontDesk,
        layout: '/admin',
      },
      {
        path: '/user/:userId/payment/history',
        component: PaymentHistory,
        layout: '/admin',
      },
      {
        path: '/user/:id',
        component: UserProfile,
        layout: '/admin',
      },
    ],
  },
];
