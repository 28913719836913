import SpinnerLoader from 'components/Misc/Spinner';
import React, { useEffect, useState } from 'react';
import { Card, CardHeader, Col, Container, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { getOneUser } from 'services/authService';
import { capitalize } from 'lodash';
import { getDateFormat } from 'services/utilService';
import { ROLES } from 'shared/constants';

function UserProfile() {
  const [showSpinner, setSpinner] = useState(true);
  const [user, setUser] = useState(true);
  const { id } = useParams();

  const fetchUser = () => {
    setSpinner(true);
    getOneUser(id)
      .then((data) => {
        setUser(data);
        setSpinner(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Something went wrong!', errorToast);
        setSpinner(false);
      });
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const getRoleString = () => {
    if (user?.role === ROLES.frontdesk) {
      return 'Front-Desk';
    }
    if (user?.hybridRole) {
      return 'Trainer/Trainee';
    }
    return capitalize(user?.role);
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-bottom">
                <div className="table-header">
                  <div className="header-left">
                    <div className="table-title">{getRoleString()} Profile</div>
                  </div>
                  <div className="header-right"></div>
                </div>
              </CardHeader>
              <div className="px-5 py-4 d-flex align-items-center">
                <img
                  src="user-image.png"
                  alt="img"
                  className="profile-img-large"
                />
                <h1 className="mb-0 ml-3">
                  {(user?.firstName || '') +
                    ' ' +
                    (user?.lastName || '') +
                    (user?.nickName ? ` (${user?.nickName})` : '')}
                </h1>
              </div>
              <div className="profile-bottom mx-5 mb-5 p-5">
                <h2>Basic Information</h2>
                <Row>
                  <Col md="4" className="my-3">
                    <div className="mb-1">Email:</div>
                    <div className="text-darker">{user?.email}</div>
                  </Col>
                  <Col md="4" className="my-3">
                    <div className="mb-1">Phone:</div>
                    <div className="text-darker">{user?.phone}</div>
                  </Col>
                  <Col md="4" className="my-3">
                    <div className="mb-1">DOB:</div>
                    <div className="text-darker">
                      {getDateFormat(user?.dob)}
                    </div>
                  </Col>
                  <Col md="4" className="my-3">
                    <div className="mb-1">Gender:</div>
                    <div className="text-darker">
                      {capitalize(user?.gender) || 'N/A'}
                    </div>
                  </Col>
                  <Col md="4" className="my-3">
                    <div className="mb-1">User Type:</div>
                    <div className="text-darker">{capitalize(user?.role)}</div>
                  </Col>
                  <Col md="4" className="my-3">
                    <div className="mb-1">
                      Joining Date{' '}
                      {user?.hybridRole &&
                        '(as ' + capitalize(user?.role) + ')'}
                      :
                    </div>
                    <div className="text-darker">
                      {getDateFormat(user?.createdAt)}
                    </div>
                  </Col>
                  {(user?.role === ROLES.trainee || user?.hybridRole) && (
                    <>
                      <Col md="4" className="my-3">
                        <div className="mb-1">Membership:</div>
                        <div className="text-darker">
                          {capitalize(user?.membership) || 'N/A'}
                        </div>
                        {user?.membership && (
                          <small className="required-color">
                            Exp: {getDateFormat(user?.membershipEnd)}
                          </small>
                        )}
                      </Col>
                      <Col md="4" className="my-3">
                        <div className="mb-1">Therapy:</div>
                        <div className="text-darker">
                          {capitalize(user?.therapyStatus)}
                        </div>
                      </Col>
                      {user?.hybridRole && (
                        <Col md="4" className="my-3">
                          <div className="mb-1">
                            Joining Date (as{' '}
                            {capitalize(
                              user?.role === ROLES.trainee
                                ? ROLES.trainer
                                : ROLES.trainee
                            )}
                            ):
                          </div>
                          <div className="text-darker">
                            {getDateFormat(user?.hybridRoleDate)}
                          </div>
                        </Col>
                      )}
                    </>
                  )}
                </Row>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default UserProfile;
