import React, { useCallback, useState } from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Input,
  Button,
} from 'reactstrap';
import { fetchAll } from 'services/attendanceService';
import SpinnerLoader from 'components/Misc/Spinner';
import toast from 'react-hot-toast';
import _debounce from 'lodash/debounce';
import * as _ from 'lodash';
import { mapTrainerData } from 'services/utilService';
import { getTrainerColumns } from 'services/utilService';
import { MultiDropdown } from 'components/Misc/MultiDropdown';
import { genderFilters } from 'shared/constants';
import { statusFilters } from 'shared/constants';
import { getRole } from 'services/utilService';
import { ROLES } from 'shared/constants';
import AddUserModal from 'components/Modals/AddUser';
import { USER_STATUS } from 'shared/constants';
import { addUser } from 'services/authService';
import { updateUser, resendCredentials } from 'services/authService';
import DeleteModal from 'components/Modals/DeleteModal';
import { deleteUser } from 'services/authService';
import { useHistory } from 'react-router-dom';
import { successToast } from 'shared/constants';
import { handleError } from 'services/utilService';
import { THERAPY_STATUS } from 'shared/constants';

function FrontDesk() {
  const history = useHistory();

  const pageSize = 10;
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [showSpinner, setSpinner] = useState(true);
  const [gender, setGender] = useState([]);
  const [status, setStatus] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  React.useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async (body = null) => {
    setSpinner(true);
    fetchAll({ ...body, role: [ROLES.frontdesk] })
      .then((data) => {
        setUsers(data.data);
        setCount(data.meta.total_count);
        setSpinner(false);
      })
      .catch((error) => {
        setSpinner(false);
        handleError(error);
      });
  };

  const pages = () => {
    let pagesArr = [];
    for (let i = 1; i <= Math.ceil(count / pageSize); i++) {
      pagesArr.push(
        <PaginationItem className={currentPage === i ? 'active' : ''}>
          <PaginationLink onClick={(e) => handlePageClick(e, i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return pagesArr;
  };

  const handlePageClick = (e, pageNumber) => {
    e.preventDefault();
    fetchUsers({
      size: pageSize,
      pageNo: pageNumber,
      ...(search.trim() && { search: search.trim() }),
      ...(status && { status }),
      ...(gender && { gender }),
    });
    setPage(pageNumber);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    debounceFn(e.target.value);
  };

  function debounceSearch(
    searchVal = search,
    statusVal = status,
    genderVal = gender
  ) {
    fetchUsers({
      size: pageSize,
      pageNo: 1,
      ...(searchVal.trim() && { search: searchVal.trim() }),
      ...(status && { status: statusVal }),
      ...(gender && { gender: genderVal }),
    });
  }

  const debounceFn = useCallback(_debounce(debounceSearch, 500), []);

  const changeStatus = (value) => {
    setStatus(value);
    debounceFn(search, value);
  };
  const changeGender = (value) => {
    setGender(value);
    debounceFn(search, status, value);
  };

  const handleAdd = async (values) => {
    try {
      setSpinner(true);
      if (editUser) {
        await updateUser(editUser?._id, {
          ...values,
        });
        toast.success('User updated!', successToast);
      } else {
        await addUser({
          ...values,
          role: ROLES.frontdesk,
          status: USER_STATUS.active,
          therapyStatus: THERAPY_STATUS.inactive,
        });
        toast.success('User added!', successToast);
      }
      setSpinner(false);
      setAddModal(false);
      fetchUsers();
    } catch (error) {
      setSpinner(false);
      handleError(error);
    }
  };

  const handleUpdate = async (userId, body) => {
    try {
      setSpinner(true);
      await updateUser(userId, body);
      fetchUsers({
        size: pageSize,
        pageNo: currentPage,
        ...(search.trim() && { search: search.trim() }),
        ...(status && { status }),
        ...(gender && { gender }),
      });
      toast.success('User updated!', successToast);
    } catch (error) {
      setSpinner(false);
      handleError(error);
    }
  };

  const handleResendCredential = async (userId) => {
    console.log('handle resend');
    try {
      setSpinner(true);
      const result = await resendCredentials(userId);
      fetchUsers({
        size: pageSize,
        pageNo: currentPage,
        ...(search.trim() && { search: search.trim() }),
        ...(status && { status }),
        ...(gender && { gender }),
      });
      console.log('res', result);
      toast.success(
        result?.message || 'Credential sent to email!',
        successToast
      );
    } catch (error) {
      setSpinner(false);
      handleError(error);
    }
  };

  const handleEdit = (user, deleteUser = false) => {
    setEditUser(user);
    deleteUser ? setDeleteModal(true) : setAddModal(true);
  };

  const handleDelete = async () => {
    try {
      setSpinner(true);
      await deleteUser(editUser?._id);
      toast.success('User deleted!', successToast);
      setSpinner(false);
      setDeleteModal(false);
      fetchUsers();
    } catch (error) {
      setSpinner(false);
      handleError(error);
    }
  };

  const handleAddButton = () => {
    setEditUser(null);
    setAddModal(true);
  };
  console.log('users->', users);
  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <Container className='mt-3' fluid>
        <div className='mb-3 d-flex align-items-center justify-content-end'>
          <div className='search-input mr-3'>
            <div className='search-icon'>
              <img src='/search-icon.svg' />
            </div>
            <Input
              className='searchBox'
              placeholder='Search'
              type='text'
              value={search}
              onChange={handleSearch}
            />
          </div>
          <div>
            <Button className='primary-button' onClick={handleAddButton}>
              Add Front-desk
            </Button>
          </div>
        </div>
        <Row>
          <div className='col'>
            <Card>
              <CardHeader className='border-0'>
                <div className='table-header'>
                  <div className='header-left'>
                    <div className='table-title'>Front-Desk</div>
                  </div>
                  <div className='header-right'>
                    {getRole() === ROLES.admin && (
                      <div className='mr-2'>
                        <MultiDropdown
                          placeholder={'Status'}
                          value={status}
                          options={statusFilters}
                          valueChange={changeStatus}
                        />
                      </div>
                    )}
                    <MultiDropdown
                      placeholder={'Gender'}
                      value={gender}
                      options={genderFilters}
                      valueChange={changeGender}
                    />
                  </div>
                </div>
              </CardHeader>
              <div className='table-responsive'>
                <Table className='dataTable align-items-center'>
                  <thead className='thead-bh icon-color-light'>
                    <tr>
                      {getTrainerColumns().map((item, index) => (
                        <th
                          key={index}
                          className={`w-${item.cols} ${item.class}`}
                          scope='col'
                        >
                          {item.name}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className='list'>
                    {users.length ? (
                      users.map((user, index) => (
                        <tr key={index}>
                          {mapTrainerData(user, history)}
                          <td className='actionDropdown px-0'>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className='btn-icon-only text-light action-bg'
                                color=''
                                role='button'
                                size='sm'
                              >
                                <img src='/action.svg' />
                              </DropdownToggle>
                              <DropdownMenu
                                className='dropdown-menu-arrow'
                                right
                              >
                                {user?.temporaryPasscode && (
                                  <div>
                                    <DropdownItem
                                      onClick={(e) =>
                                        handleResendCredential(user._id)
                                      }
                                    >
                                      <div className='d-flex align-items-center justify-content-start dropdown-menu-item'>
                                        <div>Resend Credentials</div>
                                      </div>
                                    </DropdownItem>
                                    <DropdownItem divider />
                                  </div>
                                )}

                                <DropdownItem
                                  onClick={(e) =>
                                    handleUpdate(user._id, {
                                      status:
                                        user.status === USER_STATUS.active
                                          ? USER_STATUS.inactive
                                          : USER_STATUS.active,
                                    })
                                  }
                                >
                                  <div className='d-flex align-items-center justify-content-start dropdown-menu-item'>
                                    <div>
                                      {user.status === USER_STATUS.active
                                        ? 'Deactivate'
                                        : 'Activate'}
                                    </div>
                                  </div>
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={() => handleEdit(user)}>
                                  <div className='d-flex align-items-center justify-content-start dropdown-menu-item'>
                                    <div>Edit</div>
                                  </div>
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem
                                  onClick={() => handleEdit(user, true)}
                                >
                                  <div className='d-flex align-items-center justify-content-start dropdown-menu-item'>
                                    <div>Remove</div>
                                  </div>
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <></>
                    )}
                    <tr></tr>
                  </tbody>
                </Table>
                {!showSpinner && !users.length && (
                  <div className='d-flex align-items-center justify-content-center mb-3'>
                    No records found
                  </div>
                )}
              </div>
              <CardFooter className='py-4 custom-footer'>
                <nav
                  className='d-flex align-items-center justify-content-end'
                  aria-label='...'
                >
                  <Pagination
                    className='pagination justify-content-end mb-0'
                    listClassName='justify-content-end mb-0'
                  >
                    <PaginationItem
                      className={currentPage === 1 ? 'disabled' : ''}
                    >
                      <PaginationLink
                        onClick={(e) => handlePageClick(e, currentPage - 1)}
                        tabIndex='-1'
                      >
                        <i className='fas fa-angle-left icon-color-light' />
                        <span className='sr-only'>Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    {pages().map((page, index) => (
                      <div key={index}>{page}</div>
                    ))}
                    <PaginationItem
                      className={
                        currentPage >= Math.ceil(count / pageSize)
                          ? 'disabled'
                          : ''
                      }
                    >
                      <PaginationLink
                        onClick={(e) => handlePageClick(e, currentPage + 1)}
                      >
                        <i className='fas fa-angle-right icon-color-light' />
                        <span className='sr-only'>Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      {addModal && (
        <AddUserModal
          open={addModal}
          handleClose={() => setAddModal(false)}
          role={ROLES.frontdesk}
          handleAdd={handleAdd}
          editObj={editUser}
        />
      )}
      {deleteModal && (
        <DeleteModal
          open={deleteModal}
          handleClose={() => setDeleteModal(false)}
          handleDelete={handleDelete}
          message={'Are you sure you want to remove this record?'}
          title={'Are you sure?'}
        />
      )}
    </>
  );
}

export default FrontDesk;
