import { Button, Col, Modal, Row } from 'reactstrap';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { cloneDeep } from 'lodash';
import { MEMBERSHIPS_ENUM, PAYMENT_METHODS_ENUM } from 'shared/constants';
import { ROLES } from 'shared/constants';
import {
  ADD_USER_REQUIRED_FIELDS,
  ADD_PAYMENT_HISTORY_REQUIRED_FIELDS,
} from 'shared/constants';
import toast from 'react-hot-toast';
import { errorToast } from 'shared/constants';
import { THERAPY_STATUS } from 'shared/constants';

import './Modals.css';

const AddPaymentHistory = ({ open, handleClose, handleAdd, editObj }) => {
  const { register, control, getValues } = useForm({
    defaultValues: {
      ...editObj,
      paymentDate: editObj?.paymentDate ? new Date(editObj?.paymentDate) : '',
    },
  });

  const onSubmit = async () => {
    try {
      const values = getValues();
      if (
        !values['membership'] ||
        values['membership'] === 'Select Membership'
      ) {
        toast.error('Enter required fields', errorToast);
        return;
      }

      if (
        !values['paymentMethod'] ||
        values['paymentMethod'] === 'Select Payment Method'
      ) {
        toast.error('Enter required fields', errorToast);
        return;
      }
      const required = cloneDeep(ADD_PAYMENT_HISTORY_REQUIRED_FIELDS);
      for (let i = 0; i < required.length; i++) {
        if (values[`${required[i]}`] === '') {
          toast.error('Enter required fields', errorToast);
          return;
        }
      }
      handleAdd(values);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        size='md'
        className='modal-dialog-centered payment-history-modal'
        isOpen={open}
        toggle={handleClose}
      >
        <div className='modal-header mt-3'>
          <h5 className='modal-title bolder'>Add Payment History</h5>
        </div>
        <form>
          <div className='border-top modal-body'>
            <Row>
              <Col lg='12'>
                <div className='mb-2'>
                  Membership Type <span className='required-color'>*</span>
                </div>
                <div className='position-relative mb-4'>
                  <select
                    role='button'
                    className='form-control form-select'
                    {...register('membership')}
                    defaultValue={editObj?.membership || 'Select Membership'}
                  >
                    <option disabled className='fw-bold'>
                      Select Membership
                    </option>
                    {MEMBERSHIPS_ENUM.map((membership) => {
                      return (
                        <option value={membership} className='optional-text'>
                          {membership}
                        </option>
                      );
                    })}
                  </select>
                  <img
                    className='xsmall-icon position-absolute calendar-icon'
                    src='/chevron-down.svg'
                    alt='calendar'
                    role='button'
                  />
                </div>
              </Col>

              <Col lg='12'>
                <div className='mb-2'>
                  Amount Paid <span className='required-color'>*</span>
                </div>
                <div className='amount-paid'>
                  <span class='dollar-sign'>$</span>
                  <input
                    className='form-control mb-4'
                    placeholder='Enter Amount Paid'
                    type='number'
                    min='1'
                    {...register('amount', { required: true })}
                  />
                </div>
              </Col>

              <Col lg='12'>
                <div className='mb-2'>
                  Date of Payment <span className='required-color'>*</span>
                </div>
                <Controller
                  name={'paymentDate'}
                  control={control}
                  render={({ field }) => (
                    <div className='position-relative mb-4'>
                      <ReactDatePicker
                        placeholderText='Select Date of Payment'
                        showMonthDropdown
                        showYearDropdown
                        className='w-100'
                        selected={field.value}
                        onChange={(date) => field.onChange(date)}
                        timeIntervals={1}
                        dateFormat='dd MMM, yyyy'
                      />
                      <img
                        className='xsmall-icon position-absolute calendar-icon'
                        src='/calendar.svg'
                        alt='calendar'
                        role='button'
                      />
                    </div>
                  )}
                />
              </Col>
              <Col lg='12'>
                <div className='mb-2'>
                  Payment Method <span className='required-color'>*</span>
                </div>
                <div className='position-relative mb-4'>
                  <select
                    role='button'
                    className='form-control form-select'
                    {...register('paymentMethod')}
                    defaultValue={
                      editObj?.membership || 'Select Payment Method'
                    }
                  >
                    <option disabled className='fw-bold'>
                      Select Payment Method
                    </option>
                    {PAYMENT_METHODS_ENUM.map((paymentMethod) => {
                      return (
                        <option value={paymentMethod} className='optional-text'>
                          {paymentMethod}
                        </option>
                      );
                    })}
                  </select>
                  <img
                    className='xsmall-icon position-absolute calendar-icon'
                    src='/chevron-down.svg'
                    alt='calendar'
                    role='button'
                  />
                </div>
              </Col>
            </Row>

            <div className='modal-footer d-flex justify-content-end align-items-center mt-2 mb-0 pb-1'>
              <Button onClick={handleClose} className='secondary-button'>
                Cancel
              </Button>
              <Button onClick={onSubmit} className='primary-button'>
                {editObj ? 'Update' : 'Add'}
              </Button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddPaymentHistory;
