import React from 'react';
import QRCode from 'react-qr-code';

function QRCodePage() {
  const qrCode = localStorage.getItem('tenant_id');
  return (
    <div className="qr-box d-flex align-items-center justify-content-center">
      {qrCode ? (
        <QRCode size={400} value={qrCode} />
      ) : (
        <>Login to Generate QR Code</>
      )}
    </div>
  );
}

export default QRCodePage;
