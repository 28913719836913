import { Button, Col, Modal, Row } from 'reactstrap';
import React from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { errorToast } from 'shared/constants';

const ChangePasswordModal = ({ open, handleClose, handleSubmit }) => {
  const { register, getValues } = useForm();

  const onSubmit = async () => {
    try {
      const values = getValues();
      if (!values['current'] || !values['new'] || !values['confirm']) {
        toast.error('Enter required fields', errorToast);
        return;
      }
      if (values['new'] !== values['confirm']) {
        toast.error('Passwords must match', errorToast);
        return;
      }
      handleSubmit(values);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        size="md"
        className="modal-dialog-centered"
        isOpen={open}
        toggle={handleClose}
      >
        <div className="modal-header">
          <h5 className="modal-title bolder">Change Password</h5>
        </div>
        <form>
          <div className="border-top modal-body">
            <Row>
              <Col lg="12">
                <div className="mb-2">
                  Current Password <span className="required-color">*</span>
                </div>
                <input
                  className="form-control"
                  placeholder="Enter Current Password"
                  type="password"
                  {...register('current', { required: true })}
                />
              </Col>
              <Col lg="12" className="mt-3">
                <div className="mb-2">
                  New Password <span className="required-color">*</span>
                </div>
                <input
                  className="form-control"
                  placeholder="Enter New Password"
                  type="password"
                  {...register('new', { required: true })}
                />
              </Col>
              <Col lg="12" className="mt-3">
                <div className="mb-2">
                  Confirm New Password <span className="required-color">*</span>
                </div>
                <input
                  className="form-control"
                  placeholder="Confirm New Password"
                  type="password"
                  {...register('confirm', { required: true })}
                />
              </Col>
            </Row>

            <div className="modal-footer d-flex justify-content-end align-items-center">
              <Button onClick={handleClose} className="secondary-button">
                Cancel
              </Button>
              <Button onClick={onSubmit} className="primary-button">
                Update
              </Button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;
