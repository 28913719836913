import jwtDecode from 'jwt-decode';
import { capitalize } from 'lodash';
import moment from 'moment';
import toast from 'react-hot-toast';
import { USER_STATUS } from 'shared/constants';
import { errorToast } from 'shared/constants';
import { THERAPY_STATUS } from 'shared/constants';
import { ROLES } from 'shared/constants';

export const getDateFormat = (date) => {
  if (!date) {
    return 'N/A';
  }
  return moment(date).format('DD MMM, yyyy');
};

export const getTimeFormat = (date) => {
  if (!date) {
    return 'N/A';
  }
  return moment(date).format('hh:mm A');
};

export const checkoutTime = (checkin, checkout, selectedDate) => {
  if (!checkin) {
    return 'N/A';
  } else if (!checkout) {
    if (moment(selectedDate).isSame(moment(), 'day')) {
      return 'N/A';
    }
    return '12:00 AM';
  }
  return moment(checkout).format('hh:mm A');
};

export const getDuration = (checkin, checkout, selectedDate) => {
  let duration;
  if (!checkin) {
    return 'N/A';
  } else if (!checkout) {
    if (moment(selectedDate).isSame(moment(), 'day')) {
      return '-';
    }
    const startDate = moment(checkin);
    const endDate = moment(checkin).endOf('day');
    duration = moment.duration(endDate.diff(startDate));
  } else {
    const startDate = moment(checkin);
    const endDate = moment(checkout);
    duration = moment.duration(endDate.diff(startDate));
  }

  const hours = duration.hours();
  const minutes = duration.minutes();

  return `${hours < 10 ? '0' + hours : hours}:${
    minutes < 10 ? '0' + minutes : minutes
  } hr`;
};

export const downloadData = (data, fileName, type) => {
  const blob = new Blob([data], { type });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const getRole = () => {
  const access_token = localStorage.getItem('access_token');
  const decode = jwtDecode(access_token);
  return decode.role;
};

export const decodeUserInfo = () => {
  const access_token = localStorage.getItem('access_token');
  const decode = jwtDecode(access_token);
  return decode;
};

export const getTraineeColumns = () => {
  const role = getRole();
  return [
    {
      name: 'NAME',
      cols: '20',
      class: 'name-field',
    },
    ...(role === ROLES.frontdesk
      ? [
          {
            name: 'NICK NAME',
            cols: '15',
          },
        ]
      : []),
    {
      name: 'PHONE',
      cols: '10',
    },
    ...(role === ROLES.admin
      ? [
          {
            name: 'THERAPY STATUS',
            cols: '10',
          },
        ]
      : []),
    {
      name: 'EMAIL',
      cols: '25',
    },
    ...(role === ROLES.frontdesk
      ? [
          {
            name: 'DATE OF JOINING',
            cols: '15',
          },
        ]
      : []),
    {
      name: 'MEMBERSHIP',
      cols: '15',
    },
    ...(role === ROLES.admin
      ? [
          {
            name: 'STATUS',
            cols: '15',
          },
          {
            name: '',
            cols: '5',
          },
        ]
      : []),
  ];
};

export const getPaymentHistoryColumns = () => {
  return [
    {
      name: 'MEMBERSHIP TYPE',
      cols: '20',
    },

    {
      name: 'AMOUNT PAID',
      cols: '20',
    },

    {
      name: 'DATE OF PAYMENT',
      cols: '25',
    },

    {
      name: 'MODE OF PAYMENT',
      cols: '25',
    },
    {
      name: '',
      cols: '10',
    },
  ];
};

export const mapTraineeData = (user, history) => {
  const role = getRole();
  return (
    <>
      <td className='overflowStyle pl-0 pr-0'>
        <div className='user-info-cell'>
          <div className='user-image'>
            <img src='user-image.png' />
          </div>
          <div
            className='username overflowStyle'
            role='button'
            onClick={() => history.push(`/admin/user/${user._id}`)}
          >
            {(user?.firstName || '') + ' ' + (user?.lastName || '')}
          </div>
        </div>
      </td>
      {role === ROLES.frontdesk && (
        <td className='overflowStyle pl-0 pr-0'>{user?.nickName}</td>
      )}
      <td className='overflowStyle pl-0 pr-0'>{user.phone || 'N/A'}</td>
      {role === ROLES.admin && (
        <td className='overflowStyle pl-0 pr-0'>
          <div className='d-flex align-items-center justify-content-center'>
            <div
              className={`${
                user.therapyStatus === THERAPY_STATUS.active
                  ? 'present'
                  : 'absent'
              } attendance-status mr-2`}
            ></div>
            {user.therapyStatus === THERAPY_STATUS.active
              ? 'Active'
              : 'Inactive'}
          </div>
        </td>
      )}
      <td className='overflowStyle pl-0 pr-0'>
        <div className='d-flex align-items-center justify-content-center'>
          <span>{user.email}</span>
          {user.hybridRole && (
            <span>
              <img src='/hybrid-role.svg' className='small-icon' />
            </span>
          )}
        </div>
      </td>
      {role === ROLES.frontdesk && (
        <td className='overflowStyle pl-0 pr-0'>
          {moment(user?.createdAt).format('DD MMM, yyyy')}
        </td>
      )}
      <td className='overflowStyle pl-0 pr-0'>{user.membership || 'N/A'}</td>
      {role === ROLES.admin && (
        <td className='overflowStyle pl-0 pr-0'>
          <div className='d-flex align-items-center justify-content-center'>
            <div
              className={`${
                user.status === USER_STATUS.active ? 'present' : 'absent'
              } attendance-status mr-2`}
            ></div>
            {user.status === USER_STATUS.active ? 'Active' : 'Inactive'}
          </div>
        </td>
      )}
    </>
  );
};

export const mapPaymnetHistoryData = (paymentHistory) => {
  const role = getRole();
  return (
    <>
      <td className='overflowStyle pl-0 pr-0'>{paymentHistory?.membership}</td>
      <td className='overflowStyle pl-0 pr-0'>$ {paymentHistory?.amount}</td>
      <td className='overflowStyle pl-0 pr-0'>
        {getDateFormat(paymentHistory?.paymentDate)}
      </td>
      <td className='overflowStyle pl-0 pr-0'>
        {paymentHistory?.paymentMethod}
      </td>
    </>
  );
};

export const getTrainerColumns = () => {
  const role = getRole();
  return [
    {
      name: 'NAME',
      cols: '20',
      class: 'name-field',
    },
    {
      name: 'PHONE',
      cols: '15',
    },
    {
      name: 'EMAIL',
      cols: '25',
    },
    ...(role === ROLES.admin
      ? [
          {
            name: 'STATUS',
            cols: '15',
          },
        ]
      : []),
    {
      name: 'GENDER',
      cols: '20',
    },
    ...(role === ROLES.frontdesk
      ? [
          {
            name: 'DATE OF JOINING',
            cols: '20',
          },
        ]
      : []),
    ...(role === ROLES.admin
      ? [
          {
            name: '',
            cols: '5',
          },
        ]
      : []),
  ];
};

export const mapTrainerData = (user, history) => {
  const role = getRole();
  return (
    <>
      <td className='overflowStyle pl-0 pr-0'>
        <div className='user-info-cell'>
          <div className='user-image'>
            <img src='user-image.png' />
          </div>
          <div
            className='username overflowStyle'
            role='button'
            onClick={() => history.push(`/admin/user/${user._id}`)}
          >
            {(user?.firstName || '') + ' ' + (user?.lastName || '')}
          </div>
        </div>
      </td>
      <td className='overflowStyle pl-0 pr-0'>{user.phone || 'N/A'}</td>
      <td className='overflowStyle pl-0 pr-0'>
        <div className='d-flex align-items-center justify-content-center'>
          <span>{user.email}</span>
          {user.hybridRole && (
            <span>
              <img src='/hybrid-role.svg' className='small-icon' />
            </span>
          )}
        </div>
      </td>
      {role === ROLES.admin && (
        <td className='overflowStyle pl-0 pr-0'>
          <div className='d-flex align-items-center justify-content-center'>
            <div
              className={`${
                user.status === USER_STATUS.active ? 'present' : 'absent'
              } attendance-status mr-2`}
            ></div>
            {user.status === USER_STATUS.active ? 'Active' : 'Inactive'}
          </div>
        </td>
      )}
      <td className='overflowStyle pl-0 pr-0'>
        {user.gender ? capitalize(user.gender) : 'N/A'}
      </td>
      {role === ROLES.frontdesk && (
        <td className='overflowStyle pl-0 pr-0'>
          {moment(user?.createdAt).format('DD MMM, yyyy')}
        </td>
      )}
    </>
  );
};

export const handleError = (error) => {
  if (!error) {
    return;
  }
  console.log(error);
  const msg = error?.response?.data?.error;
  const parts = msg?.split(':');
  if (!parts || !parts.length) {
    return;
  }
  toast.error(parts[parts.length - 1] || 'Something went wrong', errorToast);
};
