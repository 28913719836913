import { authorizedGetCall } from './APIsService';

export const getPaymentHistory = async (body) => {
  console.log('booo', body);
  return new Promise((resolve, reject) => {
    authorizedGetCall(
      `/user/${body?.userId}/payment/history?page=${body.pageNo || ''}&limit=${
        body?.size || ''
      }&paymentMethod=${body.paymentMethod || ''}&membership=${
        body.membership || ''
      }&search=${body.search || ''}`
    )
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};
