import { Button, Col, Modal, Row } from 'reactstrap';
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import toast from 'react-hot-toast';
import { errorToast } from 'shared/constants';

const UpdateModal = ({
  open,
  handleClose,
  handleUpdate,
  initialCheckin,
  initialCheckout,
}) => {
  const [checkin, setCheckinTime] = useState(initialCheckin);
  const [checkout, setCheckoutTime] = useState(initialCheckout);

  const clearTime = () => {
    setCheckinTime(null);
    setCheckoutTime(null);
  };

  const close = () => {
    clearTime();
    handleClose();
  };

  const update = () => {
    if (!checkin || !checkout) {
      toast.error('Select both times', errorToast);
      return;
    }
    clearTime();
    handleUpdate({ checkin, checkout });
  };

  const onCheckInChange = (value) => {
    setCheckinTime(value);
    setCheckoutTime(null);
  };

  return (
    <>
      <Modal className="modal-dialog-centered" isOpen={open} toggle={close}>
        <div className="modal-header">
          <h5 className="modal-title bolder">Update Time</h5>
        </div>
        <div className="border-top modal-body">
          <Row>
            <Col lg="12">
              <div className="mb-2">Check in</div>
              <div className="position-relative">
                <ReactDatePicker
                  className="w-100"
                  selected={checkin}
                  onChange={onCheckInChange}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={1}
                  dateFormat="hh:mm aa"
                />
                <img
                  className="small-icon position-absolute clock-icon"
                  src="/clock.svg"
                  alt="calendar"
                  role="button"
                />
              </div>
              <div className="mb-2 mt-3">Check out</div>
              <div className="position-relative">
                <ReactDatePicker
                  disabled={!checkin}
                  className={`w-100 ${!checkin && 'disabled-input'}`}
                  selected={checkout}
                  onChange={setCheckoutTime}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={1}
                  minTime={checkin}
                  maxTime={new Date().setHours(23, 59, 59)}
                  dateFormat="hh:mm aa"
                />
                <img
                  className="small-icon position-absolute clock-icon"
                  src="/clock.svg"
                  alt="calendar"
                  role="button"
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="modal-footer d-flex justify-content-end align-items-center">
          <Button onClick={close} type="button" className="secondary-button">
            Cancel
          </Button>
          <Button onClick={update} type="button" className="primary-button">
            Update
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default UpdateModal;
