import React, { useCallback, useState } from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Input,
} from 'reactstrap';
// core components
import { fetchAll } from 'services/attendanceService';
import SpinnerLoader from 'components/Misc/Spinner';
import toast from 'react-hot-toast';
import moment from 'moment';
import _debounce from 'lodash/debounce';
import DatePickerComp from 'components/DateRangePicker/DatePicker';
import { changeStatus } from 'services/attendanceService';
import Papa from 'papaparse';
import { downloadData } from 'services/utilService';
import * as _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { attendanceStatusFilters } from 'shared/constants';
import { MultiDropdown } from 'components/Misc/MultiDropdown';
import { roleFilter } from 'shared/constants';
import { ROLES } from 'shared/constants';
import { handleError } from 'services/utilService';
import { successToast } from 'shared/constants';

function AllUsers() {
  const history = useHistory();

  const pageSize = 10;
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [showSpinner, setSpinner] = useState(true);
  const [date, setDate] = useState(new Date());
  const [status, setStatus] = useState([]);
  const [role, setRole] = useState([]);
  const [shownRow, setShownRow] = useState();

  React.useEffect(() => {
    fetchUsers({ date });
  }, [date]);

  const fetchUsers = async (body = null) => {
    setSpinner(true);
    fetchAll(body)
      .then((data) => {
        setUsers(data.data);
        setCount(data.meta.total_count);
        setSpinner(false);
      })
      .catch((error) => {
        setSpinner(false);
        handleError(error);
      });
  };

  const pages = () => {
    let pagesArr = [];
    for (let i = 1; i <= Math.ceil(count / pageSize); i++) {
      pagesArr.push(
        <PaginationItem className={currentPage === i ? 'active' : ''}>
          <PaginationLink onClick={(e) => handlePageClick(e, i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return pagesArr;
  };

  const handlePageClick = (e, pageNumber) => {
    e.preventDefault();
    fetchUsers({
      size: pageSize,
      pageNo: pageNumber,
      ...(search.trim() && { search: search.trim() }),
      date,
    });
    setPage(pageNumber);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    debounceFn(e.target.value);
  };

  function debounceSearch(val, statusVal = status, roleVal = role) {
    fetchUsers({
      size: pageSize,
      pageNo: 1,
      ...(val.trim() && { search: val.trim() }),
      ...(statusVal &&
        statusVal.length === 1 && { hasAttendance: statusVal[0] }),
      date,
      ...(roleVal && roleVal.length && { role: roleVal }),
    });
  }

  const debounceFn = useCallback(_debounce(debounceSearch, 500), []);

  const changeAttendanceStatus = (value) => {
    setStatus(value);
    debounceFn(search, value);
  };

  const changeRole = (value) => {
    setRole(value);
    debounceFn(search, status, value);
  };

  const exportData = async () => {
    setSpinner(true);
    let data = await fetchAll({
      fetchAll: true,
    });
    data = data.data.map((user) => {
      return {
        NAME: (user?.firstName || '') + ' ' + (user?.lastName || ''),
        'NICK NAME': user?.nickName,
        EMAIL: user?.email,
        DATE: moment(date).format('DD MMM, yyyy'),
        'USER TYPE': _.capitalize(user?.role),
        STATUS: user?.hasAttendance ? 'Present' : 'Absent',
      };
    });
    const csvData = Papa.unparse(data);
    downloadData(
      csvData,
      `${moment(date).format('DD MMM, yyyy')}.csv`,
      'text/csv;charset=utf-8;'
    );
    setSpinner(false);
  };

  const changeStatusHandler = async (e, user, role) => {
    try {
      setSpinner(true);
      await changeStatus({ userId: user._id, date: date.toISOString(), role });
      fetchUsers({
        size: pageSize,
        pageNo: currentPage,
        ...(search.trim() && { search: search.trim() }),
        date,
      });
      toast.success('Status Changed!', successToast);
    } catch (error) {
      setSpinner(false);
      handleError(error);
    }
  };

  const getActionDropdown = (user, role) => (
    <UncontrolledDropdown>
      <DropdownToggle
        className="btn-icon-only text-light action-bg"
        color=""
        role="button"
        size="sm"
      >
        <img src="/action.svg" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-arrow" right>
        <DropdownItem onClick={(e) => changeStatusHandler(e, user, role)}>
          <div className="d-flex align-items-center justify-content-start dropdown-menu-item">
            <div>Mark as {user?.hasAttendance ? 'Absent' : 'Present'}</div>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const getAttendanceStatus = (attendance, role) => {
    const att =
      _.capitalize(attendance.find((at) => at.role === role)?.status) ||
      'Absent';
    return (
      <div className="d-flex align-items-center justify-content-center">
        <div className={`${_.lowerCase(att)} attendance-status mr-2`}></div>
        {att}
      </div>
    );
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <Container className="mt-3" fluid>
        <div className="mb-3 d-flex align-items-center justify-content-end">
          <div className="search-input mr-3">
            <div className="search-icon">
              <img src="/search-icon.svg" />
            </div>
            <Input
              className="searchBox"
              placeholder="Search"
              type="text"
              value={search}
              onChange={handleSearch}
            />
          </div>
          <div className="date-picker">
            <DatePickerComp
              date={date}
              setDate={setDate}
              maxDate={moment().toDate()}
            />
          </div>
        </div>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <div className="table-header">
                  <div className="header-left">
                    <div className="table-title">All Users</div>
                  </div>
                  <div className="header-right">
                    <div className="export-container mr-4">
                      <div
                        className="export"
                        onClick={exportData}
                        role="button"
                      >
                        Export
                      </div>
                    </div>
                    <div className="mr-3">
                      <MultiDropdown
                        placeholder={'Status'}
                        value={status}
                        options={attendanceStatusFilters}
                        valueChange={changeAttendanceStatus}
                      />
                    </div>
                    <div>
                      <MultiDropdown
                        placeholder={'User Type'}
                        value={role}
                        options={roleFilter}
                        valueChange={changeRole}
                      />
                    </div>
                  </div>
                </div>
              </CardHeader>
              <div className="table-responsive">
                <Table className="dataTable align-items-center">
                  <thead className="thead-bh icon-color-light">
                    <tr>
                      <th className="w-20 name-field" scope="col">
                        NAME
                      </th>
                      <th className="w-20" scope="col">
                        NICK NAME
                      </th>
                      <th className="w-20" scope="col">
                        DATE
                      </th>
                      <th className="w-20" scope="col">
                        USER TYPE
                      </th>
                      <th className="w-15" scope="col">
                        STATUS
                      </th>
                      <th className="w-5" scope="col"></th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {users.length ? (
                      users.map((user, index) => (
                        <>
                          <tr key={index}>
                            <td className="overflowStyle text-capitalize pl-0">
                              <div className="user-info-cell">
                                <div className="user-image">
                                  <img src="user-image.png" />
                                </div>
                                <div
                                  className="username overflowStyle"
                                  role="button"
                                  onClick={() =>
                                    history.push(`/admin/user/${user._id}`)
                                  }
                                >
                                  {(user?.firstName || '') +
                                    ' ' +
                                    (user?.lastName || '')}
                                </div>
                              </div>
                            </td>
                            <td className="overflowStyle pl-0 pr-0">
                              {user?.nickName}
                            </td>
                            <td className="overflowStyle text-capitalize pl-0 pr-0">
                              {moment(date).format('DD MMM, yyyy')}
                            </td>
                            <td className="overflowStyle pl-0 pr-0">
                              {!user?.hybridRole ? (
                                _.capitalize(user.role)
                              ) : (
                                <img
                                  src="/hybrid-role.svg"
                                  className="small-icon"
                                />
                              )}
                            </td>
                            <td className="overflowStyle pl-0 pr-0">
                              {!user?.hybridRole && (
                                <div className="d-flex align-items-center justify-content-center">
                                  <div
                                    className={`${
                                      user?.hasAttendance ? 'present' : 'absent'
                                    } attendance-status mr-2`}
                                  ></div>
                                  {user?.hasAttendance ? 'Present' : 'Absent'}
                                </div>
                              )}
                            </td>
                            <td className="actionDropdown px-0">
                              {!user?.hybridRole ? (
                                getActionDropdown(user, user.role)
                              ) : (
                                <img
                                  className="xsmall-icon"
                                  src="/chevron-down.svg"
                                  alt="chevron"
                                  role="button"
                                  onClick={() =>
                                    setShownRow((prev) =>
                                      prev > -1 && prev === index ? -1 : index
                                    )
                                  }
                                />
                              )}
                            </td>
                          </tr>
                          {user?.hybridRole && shownRow === index && (
                            <>
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{_.capitalize(ROLES.trainer)}</td>
                                <td>
                                  {getAttendanceStatus(
                                    user.attendance,
                                    ROLES.trainer
                                  )}
                                </td>
                                <td className="pl-1 pr-0">
                                  {getActionDropdown(user, ROLES.trainer)}
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{_.capitalize(ROLES.trainee)}</td>
                                <td>
                                  {getAttendanceStatus(
                                    user.attendance,
                                    ROLES.trainee
                                  )}
                                </td>
                                <td className="pl-1 pr-0">
                                  {getActionDropdown(user, ROLES.trainee)}
                                </td>
                              </tr>
                            </>
                          )}
                        </>
                      ))
                    ) : (
                      <></>
                    )}
                    <tr></tr>
                  </tbody>
                </Table>
                {!showSpinner && !users.length && (
                  <div className="d-flex align-items-center justify-content-center mb-3">
                    No records found
                  </div>
                )}
              </div>
              <CardFooter className="py-4 custom-footer">
                <nav
                  className="d-flex align-items-center justify-content-end"
                  aria-label="..."
                >
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem
                      className={currentPage === 1 ? 'disabled' : ''}
                    >
                      <PaginationLink
                        onClick={(e) => handlePageClick(e, currentPage - 1)}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left icon-color-light" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    {pages().map((page, index) => (
                      <div key={'page-' + index}>{page}</div>
                    ))}
                    <PaginationItem
                      className={
                        currentPage >= Math.ceil(count / pageSize)
                          ? 'disabled'
                          : ''
                      }
                    >
                      <PaginationLink
                        onClick={(e) => handlePageClick(e, currentPage + 1)}
                      >
                        <i className="fas fa-angle-right icon-color-light" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default AllUsers;
