import React, { useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';
import AuthHeader from 'components/Headers/AuthHeader.js';
import {
  validateResetLink,
  changePasswordWithLink,
} from 'services/authService';
import { useHistory, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { errorToast, successToast } from 'shared/constants';
import SpinnerLoader from 'components/Misc/Spinner';

import './Auth.css';

function ResetPassword() {
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    id ? checkResetLink() : setSpinner(false);
  }, []);

  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [showSpinner, setSpinner] = React.useState(true);
  const [validLink, setValidLink] = React.useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(true);

  const setPasswordVisibility = (e) => {
    const passwordInput = document.querySelector('.password-input input');
    const confirmPasswordInput = document.querySelector(
      '.confirm-password-input input'
    );
    passwordInput.type =
      passwordInput.type === 'password' ? 'text' : 'password';

    confirmPasswordInput.type =
      confirmPasswordInput.type === 'password' ? 'text' : 'password';

    setIsPasswordVisible(!isPasswordVisible);
  };

  const checkResetLink = () => {
    validateResetLink(id)
      .then((data) => {
        data.valid
          ? setValidLink(true)
          : toast.error('Link not Valid or Expired', errorToast);
        setSpinner(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        toast.error('Something went wrong', errorToast);
      });
  };

  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      toast.error('Passwords do not Match!', errorToast);
      return;
    }
    if (password.length < 6) {
      toast.error('Passwords must be atleast 6 characters', errorToast);
      return;
    }
    setSpinner(true);
    changePasswordWithLink(password, id)
      .then((data) => {
        !data.success
          ? toast.error('Password Reset Failed', errorToast)
          : toast.success('Password reset Successfully!', successToast);
        setSpinner(false);
        history.push('/auth/login');
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        toast.error('Something went wrong', errorToast);
      });
  };

  return (
    <>
      <SpinnerLoader adminView={true} showSpinner={showSpinner} />
      <AuthHeader />
      <div className='loginForm'>
        <Container className='mt-n18'>
          <Row className='justify-content-center'>
            <Col lg='5' md='7'>
              <Card className='bg-default-login border-0 mx-4 mb-0 py-4 px-3'>
                <CardBody className='px-lg-4 py-lg-3'>
                  <div className='d-flex justify-content-center my-5'>
                    <img
                      src={'/gym-app-logo.png'}
                      className='bt_logo'
                      alt='logo'
                    />
                  </div>
                  <div className='horizontal-ruler'></div>
                  <div className='text-center text-muted'>
                    <p className='auth-title'>Create New Password</p>
                  </div>
                  <Form role='form' className='auth-form'>
                    <FormGroup>
                      <div className='password-input'>
                        <Input
                          disabled={!validLink}
                          placeholder='New Password'
                          type='password'
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className='lock-icon'>
                          <img src='/lock-icon.svg' />
                        </div>
                        <div
                          className='eye-icon'
                          onClick={(e) => setPasswordVisibility(e)}
                        >
                          {isPasswordVisible ? (
                            <i className='fa fa-eye-slash' />
                          ) : (
                            <i className='fas fa-eye' />
                          )}
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <div className='password-input confirm-password-input'>
                        <Input
                          // disabled={!validLink}
                          placeholder='Confirm Password'
                          type='password'
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <div className='lock-icon'>
                          <img src='/lock-icon.svg' />
                        </div>
                        <div
                          className='eye-icon'
                          onClick={(e) => setPasswordVisibility(e)}
                        >
                          {isPasswordVisible ? (
                            <i className='fa fa-eye-slash' />
                          ) : (
                            <i className='fas fa-eye' />
                          )}
                        </div>
                      </div>
                    </FormGroup>
                    <div className='text-center'>
                      <Button
                        className='default-button-background w-60 mt-4 py-1 login-button'
                        color='info'
                        type='button'
                        onClick={handleSubmit}
                      >
                        <h3 className='mb-0 text-white py-1'>
                          Create Password
                        </h3>
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ResetPassword;
