import { Button, Col, Modal, Row } from 'reactstrap';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { capitalize, cloneDeep } from 'lodash';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { MEMBERSHIPS_ENUM } from 'shared/constants';
import { ROLES } from 'shared/constants';
import { ADD_USER_REQUIRED_FIELDS } from 'shared/constants';
import toast from 'react-hot-toast';
import { errorToast } from 'shared/constants';
import { THERAPY_STATUS } from 'shared/constants';
import { genderFilters } from 'shared/constants';

const AddUserModal = ({ open, handleClose, role, handleAdd, editObj }) => {
  const { register, control, getValues, watch, setValue } = useForm({
    defaultValues: {
      ...editObj,
      dob: editObj?.dob ? new Date(editObj?.dob) : '',
      membershipStart: editObj?.membershipStart
        ? new Date(editObj?.membershipStart)
        : null,
      membershipEnd: editObj?.membershipEnd
        ? new Date(editObj?.membershipEnd)
        : null,
      therapyStatus:
        editObj?.therapyStatus === THERAPY_STATUS.active ? true : false,
    },
  });

  const onSubmit = async () => {
    try {
      const values = getValues();
      if (
        (!values['gender'] || values['gender'] === 'Select Gender') &&
        role !== ROLES.admin
      ) {
        toast.error('Enter required fields', errorToast);
        return;
      }
      const required = cloneDeep(ADD_USER_REQUIRED_FIELDS);
      for (let i = 0; i < required.length; i++) {
        if (values[`${required[i]}`] === '') {
          toast.error('Enter required fields', errorToast);
          return;
        }
      }
      if (
        !values['membership'] ||
        values['membership'] === 'Select Membership'
      ) {
        delete values['membership'];
      }
      if (
        values['membership'] &&
        (!values['membershipStart'] || !values['membershipEnd'])
      ) {
        toast.error('Enter both dates for membership', errorToast);
        return;
      }
      handleAdd(values);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        size="lg"
        className="modal-dialog-centered"
        isOpen={open}
        toggle={handleClose}
      >
        <div className="modal-header">
          <h5 className="modal-title bolder">
            {editObj ? 'Update ' : 'Add New '}
            {role === ROLES.frontdesk ? 'Front-Desk' : capitalize(role)}
          </h5>
        </div>
        <form>
          <div className="border-top modal-body">
            <Row>
              <Col lg="6">
                <div className="mb-2">
                  First Name <span className="required-color">*</span>
                </div>
                <input
                  className="form-control"
                  placeholder="Enter First Name"
                  type="text"
                  {...register('firstName', { required: true })}
                />
              </Col>
              <Col lg="6">
                <div className="mb-2">
                  Last Name <span className="required-color">*</span>
                </div>
                <input
                  className="form-control"
                  placeholder="Enter Last Name"
                  type="text"
                  {...register('lastName', { required: true })}
                />
              </Col>
            </Row>

            {role !== ROLES.frontdesk && role !== ROLES.admin && (
              <Row className="mt-3">
                <Col lg="6">
                  <div className="mb-2">
                    Nick Name <span className="required-color">*</span>
                  </div>
                  <input
                    disabled={editObj}
                    className={`form-control ${editObj && 'disabled-input'}`}
                    placeholder="Enter Nick Name"
                    type="text"
                    {...register('nickName', { required: true })}
                  />
                </Col>
                <Col lg="6">
                  <div className="mb-2">Date of Birth</div>
                  <Controller
                    name={'dob'}
                    control={control}
                    render={({ field }) => (
                      <div className="position-relative">
                        <ReactDatePicker
                          placeholderText="Select Date of Birth"
                          showMonthDropdown
                          showYearDropdown
                          className="w-100"
                          selected={field.value}
                          onChange={(date) => field.onChange(date)}
                          timeIntervals={1}
                          dateFormat="dd MMM, yyyy"
                        />
                        <img
                          className="xsmall-icon position-absolute calendar-icon"
                          src="/calendar.svg"
                          alt="calendar"
                          role="button"
                        />
                      </div>
                    )}
                  />
                </Col>
              </Row>
            )}

            <Row className="mt-3">
              <Col lg="6">
                <div className="mb-2">Phone</div>
                <Controller
                  name="phone"
                  control={control}
                  rules={{
                    validate: (value) =>
                      value ? isValidPhoneNumber(value) : true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      className={`form-input`}
                      value={value}
                      placeholder="Enter Phone"
                      onChange={onChange}
                      defaultCountry="US"
                      id="phone"
                    />
                  )}
                />
              </Col>
              <Col lg="6">
                <div className="mb-2">
                  Email <span className="required-color">*</span>
                </div>
                <input
                  disabled={editObj}
                  className={`form-control ${editObj && 'disabled-input'}`}
                  placeholder="Enter Email"
                  type="email"
                  {...register('email', { required: true })}
                />
              </Col>
            </Row>

            <Row className="mt-3">
              {role !== ROLES.admin && (
                <Col lg="6">
                  <div className="mb-2">
                    Gender <span className="required-color">*</span>
                  </div>
                  <div className="position-relative">
                    <select
                      role="button"
                      className="form-control form-select"
                      {...register('gender')}
                      defaultValue={editObj?.membership || 'Select Gender'}
                    >
                      <option disabled className="fw-bold">
                        Select Gender
                      </option>
                      {genderFilters.map((gender) => {
                        return (
                          <option
                            key={gender.value}
                            value={gender.value}
                            className="optional-text"
                          >
                            {gender.label}
                          </option>
                        );
                      })}
                    </select>
                    <img
                      className="xsmall-icon position-absolute calendar-icon"
                      src="/chevron-down.svg"
                      alt="calendar"
                      role="button"
                    />
                  </div>
                </Col>
              )}
              {role === ROLES.trainee && (
                <Col lg="6">
                  <div className="mb-2">Membership Type</div>
                  <div className="position-relative">
                    <select
                      role="button"
                      className="form-control form-select"
                      {...register('membership')}
                      defaultValue={editObj?.membership || 'Select Membership'}
                    >
                      <option disabled className="fw-bold">
                        Select Membership
                      </option>
                      {MEMBERSHIPS_ENUM.map((membership) => {
                        return (
                          <option value={membership} className="optional-text">
                            {membership}
                          </option>
                        );
                      })}
                    </select>
                    <img
                      className="xsmall-icon position-absolute calendar-icon"
                      src="/chevron-down.svg"
                      alt="calendar"
                      role="button"
                    />
                  </div>
                </Col>
              )}
            </Row>

            {role === ROLES.trainee && (
              <>
                <Row className="mt-3">
                  <Col lg="6">
                    <div className="mb-2">Membership Start</div>
                    <Controller
                      name={'membershipStart'}
                      control={control}
                      render={({ field }) => (
                        <div className="position-relative">
                          <ReactDatePicker
                            disabled={
                              watch('membership') === 'Select Membership'
                            }
                            className={`w-100 ${
                              watch('membership') === 'Select Membership' &&
                              'disabled-input'
                            }`}
                            showMonthDropdown
                            showYearDropdown
                            selected={field.value}
                            onChange={(date) => {
                              setValue('membershipEnd', null);
                              field.onChange(date);
                            }}
                            dateFormat="dd MMM, yyyy"
                            placeholderText="Select Membership Start Date"
                          />
                          <img
                            className="xsmall-icon position-absolute calendar-icon"
                            src="/calendar.svg"
                            alt="calendar"
                            role="button"
                          />
                        </div>
                      )}
                    />
                  </Col>
                  <Col lg="6">
                    <div className="mb-2">Membership End</div>
                    <Controller
                      name={'membershipEnd'}
                      control={control}
                      render={({ field }) => (
                        <div className="position-relative">
                          <ReactDatePicker
                            disabled={
                              watch('membership') === 'Select Membership' ||
                              !watch('membershipStart')
                            }
                            className={`w-100 ${
                              (watch('membership') === 'Select Membership' ||
                                !watch('membershipStart')) &&
                              'disabled-input'
                            }`}
                            showMonthDropdown
                            showYearDropdown
                            selected={field.value}
                            onChange={(date) => field.onChange(date)}
                            dateFormat="dd MMM, yyyy"
                            minDate={watch('membershipStart')}
                            placeholderText="Select Membership End Date"
                          />
                          <img
                            className="xsmall-icon position-absolute calendar-icon"
                            src="/calendar.svg"
                            alt="calendar"
                            role="button"
                          />
                        </div>
                      )}
                    />
                  </Col>
                </Row>

                <div className="mt-3">
                  <span>
                    <input
                      id="therapyStatus"
                      className="primary-checkbox"
                      type="checkbox"
                      {...register('therapyStatus')}
                    />
                  </span>
                  <label
                    htmlFor="therapyStatus"
                    className="mb-0 align-text-bottom px-2"
                    role="button"
                  >
                    Therapy Status
                  </label>
                </div>
              </>
            )}
            <div className="modal-footer d-flex justify-content-end align-items-center">
              <Button onClick={handleClose} className="secondary-button">
                Cancel
              </Button>
              <Button onClick={onSubmit} className="primary-button">
                {editObj ? 'Update' : 'Add'}
              </Button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddUserModal;
