/** @jsxImportSource @emotion/react */
import { Component } from 'react';
import Button from '@atlaskit/button';
import { components } from 'react-select';
import Select from 'react-select';
import { defaultTheme } from 'react-select';

const { colors } = defaultTheme;

const selectStyles = {
  control: (provided) => ({
    ...provided,
    width: '96%',
    margin: 8,
    borderColor: '#DEE2E6',
  }),
  menu: () => null,
};

const Option = (props) => {
  return (
    <div className="border-bottom">
      <components.Option className="d-flex align-items-center" {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          className="mr-3"
          role="button"
        />
        <label className="dropdown-label mb-0 py-1" role="button">
          {props.label}
        </label>
      </components.Option>
    </div>
  );
};

export class MultiDropdown extends Component {
  state = {
    isOpen: false,
    value: this.props.value.length
      ? this.props.value.map((val) =>
          this.props.options.find((option) => option.value === val)
        )
      : [],
  };

  toggleOpen = () => {
    this.setState((state) => ({ isOpen: !state.isOpen }));
  };

  onSelectChange = (value) => {
    this.setState({ value });
    this.props.valueChange(value.map((val) => val.value));
  };

  selectedClose = (selected) => {
    this.toggleOpen(false);
    const newValue = this.state.value.filter(
      (val) => val.value !== selected.value
    );
    this.setState({
      value: newValue,
    });
    this.props.valueChange(newValue.map((val) => val.value));
  };
  render() {
    const { isOpen, value } = this.state;
    return (
      <Dropdown
        isOpen={isOpen}
        onClose={this.toggleOpen}
        target={
          <Button
            className="w-100 align-items-center multiDropdown"
            iconAfter={<ChevronDown />}
            onClick={this.toggleOpen}
            isSelected={isOpen}
          >
            <div className="d-flex">
              <span className="icon-filter mr-3">
                <img src="/filter.svg" className="small-icon" alt="filter" />
              </span>
              {value.length ? (
                <div>
                  {value.map((val) => (
                    <div key={val.value} className="selectedLabel mr-1">
                      <span className="mr-2">{val.label}</span>
                      <img
                        alt="close"
                        src="/close.svg"
                        className="selectedClose"
                        onClick={() => this.selectedClose(val)}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <small>{this.props.placeholder}</small> || ''
              )}
            </div>
          </Button>
        }
      >
        <div data-toggle="popover" data-trigger="focus">
          <Select
            autoFocus
            backspaceRemovesValue={false}
            components={{ DropdownIndicator, IndicatorSeparator: null, Option }}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            isClearable={false}
            menuIsOpen
            isMulti
            closeMenuOnSelect={false}
            onChange={this.onSelectChange}
            options={this.props.options}
            styles={selectStyles}
            tabSelectsValue={false}
            value={value}
            allowSelectAll={true}
          />
        </div>
      </Dropdown>
    );
  }
}

const Menu = (props) => {
  return <div className="menuDropdown" {...props} />;
};
const Blanket = (props) => (
  <div
    css={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);
const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div className="my-dropdown" css={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
const Svg = (p) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);
const DropdownIndicator = () => (
  <div css={{ color: colors.neutral20, height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);
const ChevronDown = () => (
  <Svg style={{ marginRight: -6 }}>
    <path
      d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Svg>
);
