import './DateRangeSelector.css';
import DatePicker from 'react-datepicker';

const DatePickerComp = ({ date, setDate, maxDate }) => {
  return (
    <div className="filter-date position-relative">
      <DatePicker
        dateFormat="dd MMM, yyyy"
        selected={date}
        onChange={setDate}
        {...{ maxDate }}
      />
      <img
        className="xsmall-icon position-absolute calendar-icon"
        src="/calendar.svg"
        alt="calendar"
        role="button"
      />
    </div>
  );
};

export default DatePickerComp;
