import React, { useCallback, useState } from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Input,
  Button,
} from 'reactstrap';
import { getPaymentHistory } from 'services/paymentHistoryService';
import SpinnerLoader from 'components/Misc/Spinner';
import toast from 'react-hot-toast';
import _debounce from 'lodash/debounce';
import * as _ from 'lodash';
import { getPaymentHistoryColumns } from 'services/utilService';
import { mapPaymnetHistoryData } from 'services/utilService';
import { paymentMethodFilter } from 'shared/constants';
import { membershipFilters } from 'shared/constants';
import { getRole } from 'services/utilService';
import { ROLES } from 'shared/constants';
import { MultiDropdown } from 'components/Misc/MultiDropdown';
import { updateUser } from 'services/authService';
import AddPaymentHistoryModal from 'components/Modals/AddPaymentHistory';
import { addPaymentHistory } from 'services/authService';
import DeleteModal from 'components/Modals/DeleteModal';
import { deletePaymentHistory } from 'services/authService';
import { useHistory } from 'react-router-dom';
import { successToast } from 'shared/constants';
import { handleError } from 'services/utilService';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

function PaymentHistory() {
  const { userId } = useParams();
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  const pageSize = 10;
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [showSpinner, setSpinner] = useState(true);
  const [membership, setMembership] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  React.useEffect(() => {
    console.log('useeffect', { paymentMethod, membership });
    fetchPaymentHistory();
  }, []);

  const fetchPaymentHistory = async (body = null) => {
    setSpinner(true);
    getPaymentHistory({ ...body, userId })
      .then((data) => {
        setPaymentHistory(data.data.paymentHistory);
        setCount(data.meta.totalCount);
        setSpinner(false);
      })
      .catch((error) => {
        setSpinner(false);
        handleError(error);
      });
  };
  const pages = () => {
    let pagesArr = [];
    for (let i = 1; i <= Math.ceil(count / pageSize); i++) {
      pagesArr.push(
        <PaginationItem className={currentPage === i ? 'active' : ''}>
          <PaginationLink onClick={(e) => handlePageClick(e, i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return pagesArr;
  };

  const handlePageClick = (e, pageNumber) => {
    e.preventDefault();
    fetchPaymentHistory({
      size: pageSize,
      pageNo: pageNumber,
      ...(search.trim() && { search: search.trim() }),
      ...(paymentMethod && { paymentMethod }),
      ...(membership && { membership }),
    });
    setPage(pageNumber);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    debounceFn({ search: e.target.value, paymentMethod, membership });
  };

  function debounceSearch({ search, paymentMethod, membership }) {
    fetchPaymentHistory({
      size: pageSize,
      pageNo: 1,
      ...(search.trim() && { search: search.trim() }),
      ...(paymentMethod ? { paymentMethod } : {}),
      ...(membership ? { membership } : {}),
    });
  }

  const debounceFn = useCallback(_debounce(debounceSearch, 500), []);

  const changePaymentMethod = (value) => {
    setPaymentMethod(value);
    debounceFn({ search, paymentMethod: value, membership });
  };

  const changeMembership = (value) => {
    setMembership(value);
    debounceFn({ search, membership: value, paymentMethod });
  };

  const handleUpdate = async (userId, body) => {
    try {
      setSpinner(true);
      await updateUser(userId, body);
      fetchPaymentHistory({
        size: pageSize,
        pageNo: currentPage,
        ...(search.trim() && { search: search.trim() }),
        ...(paymentMethod && { paymentMethod }),
        ...(membership && { membership }),
      });
      toast.success('User updated!', successToast);
    } catch (error) {
      setSpinner(false);
      handleError(error);
    }
  };

  const handleAdd = async (values) => {
    try {
      setSpinner(true);
      if (editUser) {
        await updateUser(editUser?._id, {
          ...values,
          therapyStatus: values?.therapyStatus ? 'ACTIVE' : 'INACTIVE',
        });
        toast.success('User updated!', successToast);
      } else {
        await addPaymentHistory(values, userId);
        toast.success('Payment history added!', successToast);
      }
      setSpinner(false);
      setAddModal(false);
      fetchPaymentHistory();
    } catch (error) {
      setSpinner(false);
      handleError(error);
    }
  };

  const handleEdit = (user, deleteUser = false) => {
    setEditUser(user);
    deleteUser ? setDeleteModal(true) : setAddModal(true);
  };

  const handleDeletePaymentHistory = async (userId, historyId) => {
    try {
      setSpinner(true);
      await deletePaymentHistory(userId, historyId);
      toast.success('Payment History deleted!', successToast);
      setSpinner(false);
      setDeleteModal(false);
      fetchPaymentHistory();
    } catch (error) {
      setSpinner(false);
      handleError(error);
    }
  };

  const handleAddButton = () => {
    setEditUser(null);
    setAddModal(true);
  };

  const handleRoleChange = (user) => {
    handleUpdate(user._id, {
      hybridRole: true,
      hybridRoleDate: new Date(),
    });
    toast.success('User is made a Trainer as well', successToast);
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <Container className='mt-3' fluid>
        <div className='mb-3 d-flex align-items-center justify-content-end'>
          <div className='search-input mr-3'>
            <div className='search-icon'>
              <img src='/search-icon.svg' />
            </div>
            <Input
              className='searchBox'
              placeholder='Search'
              type='text'
              value={search}
              onChange={handleSearch}
            />
          </div>
          <div>
            <Button onClick={handleAddButton} className='primary-button'>
              Add
            </Button>
          </div>
        </div>
        <Row>
          <div className='col'>
            <Card>
              <CardHeader className='border-0'>
                <div className='table-header'>
                  <div className='header-left'>
                    <div className='table-title'>Payment History</div>
                  </div>
                  <div className='header-right'>
                    <div className='mr-2'>
                      <MultiDropdown
                        placeholder={'Payment Method'}
                        value={paymentMethod}
                        options={paymentMethodFilter}
                        valueChange={changePaymentMethod}
                      />
                    </div>
                    <MultiDropdown
                      placeholder={'Membership'}
                      value={membership}
                      options={membershipFilters}
                      valueChange={changeMembership}
                    />
                  </div>
                </div>
              </CardHeader>
              <div className='table-responsive'>
                <Table className='dataTable align-items-center'>
                  <thead className='thead-bh icon-color-light'>
                    <tr>
                      {getPaymentHistoryColumns().map((item, index) => (
                        <th
                          key={index}
                          className={`w-${item.cols} ${item.class}`}
                          scope='col'
                        >
                          {item.name}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className='list'>
                    {paymentHistory.length ? (
                      paymentHistory.map((paymentHistory, index) => (
                        <tr key={index}>
                          {mapPaymnetHistoryData(paymentHistory)}
                          {getRole() !== ROLES.frontdesk && (
                            <td className='actionDropdown px-0'>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className='btn-icon-only text-light action-bg'
                                  color=''
                                  role='button'
                                  size='sm'
                                >
                                  <img src='/action.svg' />
                                </DropdownToggle>
                                <DropdownMenu
                                  className='dropdown-menu-arrow'
                                  right
                                >
                                  <DropdownItem
                                    onClick={(e) =>
                                      handleDeletePaymentHistory(
                                        paymentHistory.userId,
                                        paymentHistory._id
                                      )
                                    }
                                  >
                                    <div className='d-flex align-items-center justify-content-start dropdown-menu-item'>
                                      <div>Delete</div>
                                    </div>
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          )}
                        </tr>
                      ))
                    ) : (
                      <></>
                    )}
                    <tr></tr>
                  </tbody>
                </Table>
                {!showSpinner && !paymentHistory.length && (
                  <div className='d-flex align-items-center justify-content-center mb-3'>
                    No records found
                  </div>
                )}
              </div>
              <CardFooter className='py-4 custom-footer'>
                <nav
                  className='d-flex align-items-center justify-content-end'
                  aria-label='...'
                >
                  <Pagination
                    className='pagination justify-content-end mb-0'
                    listClassName='justify-content-end mb-0'
                  >
                    <PaginationItem
                      className={currentPage === 1 ? 'disabled' : ''}
                    >
                      <PaginationLink
                        onClick={(e) => handlePageClick(e, currentPage - 1)}
                        tabIndex='-1'
                      >
                        <i className='fas fa-angle-left icon-color-light' />
                        <span className='sr-only'>Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    {pages().map((page, index) => (
                      <div key={index}>{page}</div>
                    ))}
                    <PaginationItem
                      className={
                        currentPage >= Math.ceil(count / pageSize)
                          ? 'disabled'
                          : ''
                      }
                    >
                      <PaginationLink
                        onClick={(e) => handlePageClick(e, currentPage + 1)}
                      >
                        <i className='fas fa-angle-right icon-color-light' />
                        <span className='sr-only'>Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      {addModal && (
        <AddPaymentHistoryModal
          open={addModal}
          handleClose={() => setAddModal(false)}
          handleAdd={handleAdd}
          editObj={editUser}
        />
      )}
      {deleteModal && (
        <DeleteModal
          open={deleteModal}
          handleClose={() => setDeleteModal(false)}
          handleDelete={handleDelete}
          message={'Are you sure you want to remove this record?'}
          title={'Are you sure?'}
        />
      )}
    </>
  );
}

export default PaymentHistory;
