import React from 'react';
import classnames from 'classnames';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';
import AuthHeader from 'components/Headers/AuthHeader.js';
import { resetPassword } from 'services/authService';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { errorToast, successToast } from 'shared/constants';
import SpinnerLoader from 'components/Misc/Spinner';

import './Auth.css';

function ForgotPassword() {
  const history = useHistory();

  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [showSpinner, setSpinner] = React.useState(false);

  const handleSubmit = async () => {
    setSpinner(true);
    resetPassword(email)
      .then((data) => {
        data.success
          ? toast.success(
              'A rest link has been sent to your associated email. Click to reset your password.',
              successToast
            )
          : toast.error('User not Registered', errorToast);
        setSpinner(false);
      })
      .catch(() => {
        toast.error('Something went wrong', errorToast);
        setSpinner(false);
      });
  };

  const keyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <>
      <SpinnerLoader adminView={true} showSpinner={showSpinner} />
      <AuthHeader />
      <div className="loginForm">
        <Container className="mt-n18">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-default-login border-0 mx-4 mb-0 py-4 px-3">
                <CardBody className="px-lg-4 py-lg-3">
                  <div className="d-flex justify-content-center my-5">
                    <img
                      src={'/gym-app-logo.png'}
                      className="bt_logo"
                      alt="logo"
                    />
                  </div>
                  <div className="horizontal-ruler"></div>
                  <div className="text-center text-muted">
                    <p className="auth-title">Reset Password</p>
                  </div>
                  <Form role="form" className="auth-form">
                    <div className="email-input">
                      <Input
                        placeholder="Email Address"
                        className="input-primary-style"
                        type="email"
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(true)}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={keyDown}
                      />
                      <div className="envelope-icon">
                        <i className="fas fa-envelope " />
                      </div>
                    </div>
                    <div className="forgotpassword-footer">
                      <div className="text-center">
                        <Button
                          className="default-button-background w-40 mt-4 py-1 login-button"
                          color="info"
                          type="button"
                          onClick={handleSubmit}
                        >
                          <h3 className="mb-0 text-white py-1">Send Link</h3>
                        </Button>
                      </div>
                      <div className="d-flex justify-content-center mt-3 back-to-login">
                        <p>Go back to</p>
                        <p
                          onClick={(e) => history.push('/auth/login')}
                          className="back-login px-2 mb-0 signin-text"
                          role={'button'}
                        >
                          Sign in
                        </p>
                      </div>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ForgotPassword;
